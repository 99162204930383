<template>
  
      <input
      style="padding: 6px 6px 6px 48px"
      :value="value"
      v-on="$listeners"
    />

</template>


<script>

export default{
        props: {
            value: {
                type: String,
                default: ''
            }
        }
}
</script>
  