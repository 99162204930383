
<template>
  <div class="cell large-10" style="text-align: center">
    <div class="grid-x" style="padding:0px 0px 0px 0px;">
      <template v-if="view === 'Bet Calculator'">

        <h1 class="cell auto qmajorheader" >{{langConv(view) }} {{ langConv(entrymode) }}</h1>
        <div class="cell shrink qsmallfont" style="border-bottom: 2px solid; margin-top:10px;padding-right:10px ">{{langConv('Total Stake')}}: 
            <span class="qlargefont">{{ payout }}</span>
            <span class="qsmallfont" style="margin-left:10px;padding-right: 10px" v-if="$store.state.isadmin">{{ langConv("Log Freebies") }}
                <input
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                  @change.self="setforFreebies"
                 />
            </span>
        </div>
        <h2 class="cell qsubheader" >{{ langConv('Use our super flexible bet calculator to figure out your combo bets') }} </h2>
      
    </template>

      <template v-if="view === 'bethistory'">
        <div class="cell shrink">
          <button class="button small" v-on:click="backtoCalc()"
            v-html="langConv('Back to Calc')">
          </button>
        </div>
        <div class="cell shrink">
          <button class="button small" v-on:click="deleteBet()" v-html="langConv('Delete')"></button>
        </div>
      </template>

      <div class="cell" v-if="view === 'bethistory'">
        <span
          >{{ langConv("From")}} :
          <datepicker
            v-model="fromdate"
            :clearable="false"
            :editable="false"
          ></datepicker
        ></span>
        <span
          >{{ langConv("From")}} :
          <datepicker
            v-model="todate"
            :clearable="false"
            :editable="false"
          ></datepicker
        ></span>
      </div>

      <div class="cell" v-if="view === 'bethistory'">
        <div class="grid-x">
          <div class="cell small-1"></div>
          <div class="cell small-10">
            <autocomplete
              :search="searchTitle"
              :get-result-value="getResultValue"
              placeholder="langConv('find teams, competitions and more')"
              @submit="betHistory"
            ></autocomplete>
          </div>
        </div>
      </div>
    </div>
    <template v-if="view === 'bethistory'">
      <div class="grid-x" style="font-size: small">
        <div class="cell small-7" v-html="langConv('Bet Title')"></div>
        <div class="cell small-1" v-html="langConv('No Bets')"></div>
        <div class="cell small-1" v-html="langConv('Graded')"></div>
        <div class="cell small-1" v-html="langConv('Total Stake')"></div>
        <div class="cell small-2" v-html="langConv('Last Edited')"></div>
      </div>
      <div style="height: 510px; overflow-x: hidden; overflow-y: auto">
        <div
          v-for="(betheaders, id) in betheaders"
          @click="selectRow(betheaders.GUID, id)"
          :key="betheaders.GUID"
          :class="{ highlight: betheaders.GUID == selectedGUID }"
        >
          <div v-on:click="getBetDetail">
            <div class="grid-x" style="font-size: small">
              <div class="cell small-7">
                {{
                  $displayDate(betheaders.StartDate, $dateformattype.daymonth)
                }}
                - {{ betheaders.Title }}
              </div>
              <div class="cell small-1">{{ betheaders.NoBets }}</div>
              <div
                class="cell small-1"
                :style="{ color: valueColour(betheaders.OverallGrading) }"
              >
                {{ betheaders.OverallGrading }}
              </div>
              <div class="cell small-1">{{ betheaders.PayOut }}</div>
              <div class="cell small-2">
                {{
                  $displayDate(betheaders.Updated, $dateformattype.daymonthtime)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="view === 'Bet Calculator'">
        
      <div v-if="gradedBet(betheaders)" style="color: green" 
        v-html="langConv('Bet Already Graded')">
      </div>

      <div class="grid-x" style="font-size: x-small; padding:0px 0px 0px 4px">
       
        <div class="cell small-5" style="padding-top:10px">
          <autocomplete
            :search="searchDropdownData"
            :get-result-value="getResultValue"
            :placeholder="langConv('find teams, competitions and more')"
            @submit="addBetNameEntry"
       
            :disabled="gradedBet(betheaders)"
          >
            <template
              #default="{
                rootProps,
                inputProps,
                inputListeners,
                resultListProps,
                resultListListeners,
                results,
                resultProps,
              }"
            >
              <div v-bind="rootProps">
                <custom-input
                  v-bind="inputProps"
                  v-on="inputListeners"
                  :class="[
                    'autocomplete-input',
                    { 'autocomplete-input-no-results': noResults },
                    { 'autocomplete-input-focused': focused },
                  ]"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  v-on:input="captureInput"
                >
                </custom-input>
                <ul
                  v-if="noResults"
                  class="autocomplete-result-list"
                  style="position: absolute; z-index: 1; width: 100%; top: 100%"
                >
                  <li class="autocomplete-result">{{ langConv('No results found') }}</li>
                </ul>
                <ul v-bind="resultListProps" v-on="resultListListeners">
                  <li
                    v-for="(result, index) in results"
                    :key="resultProps[index].id"
                    v-bind="resultProps[index]"
                  >
                    <span v-html="getResultFormattedValue(result)"></span>
                  </li>
                </ul>
              </div>
            </template>
          </autocomplete>
        </div>

        <div class="cell small-2">
          {{langConv('No Bets')}} : {{ nobets }} <br />
          <button v-on:click="noBets(1)" class="button tiny qlargefont" style="margin-right:5px">+</button>
          <button v-on:click="noBets(-1)" class="button tiny qlargefont">-</button>
        </div>
        <div class="cell shrink" v-if="$store.state.isadmin">
        <div class="cell shrink">
           
          <button class="button small" style="margin:10px 0px 0px 5px" v-on:click="showBetHistory()" v-html="langConv('H')">
          </button>
       </div>
       </div>
       <div class="cell shrink" v-if="$store.state.isadmin">
        <button class="button small" style="margin:10px 0px 0px 5px; color:white"  v-on:click="selectStrategy()" v-if="!gradedBet(betheaders)" v-html="langConv('S')">
           
          </button>
        </div>
        <div class="cell shrink">
          <button class="button small qbordercolour" style="margin:10px 0px 0px 5px" v-on:click="clearBet()"><img style="height:13px;width:13px" class="qwhiteicon" :src="require('/public/assets/images/delete-26.png')" alt="Clear Calculator Entires" /></button>
        </div>
        <div class="cell shrink" >
            <JsonExcel style="margin:10px 0px 0px 5px" class="button small qbordercolour" :data="excelifyBet()"  worksheet="Results"  type="csv" :name="concatBetHeaders() + '.xls'"><img style="height:13px;width:13px" class="qwhiteicon" :src="require('/public/assets/images/download-26.png')" alt="Download" /></JsonExcel> 
        </div>
       
      </div>

      <div v-for="(selectedbet, i) in selectedbets" :key="i">
        <div style="font-size: small; background-color: gold">
          <div class="grid-x">
            <div class="cell small-2">
              <strong>Bet No: {{ selectedbet.betno }}</strong>
            </div>
            <div class="cell small-9">
              <strong>{{ selectedbet.betname }}</strong> - ({{
                selectedbet.bettype
              }})
            </div>
            <div class="cell small-1">
              <button
                @click="deleteSelectedBet(selectedbet.betno)"
                :disabled="gradedBet(betheaders)"
              >
                x
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-y" style="font-size: x-small">
        <div class="cell small-12 small-cell-block-y" style="font-size:8px; text-align: center">
          <div class="grid-x">
            <div class="cell shrink">
              <span style="padding: 5px; color: white">x</span>
            </div>
            <div class="cell small-2" v-html="langConv('Description')"></div>
            <div class="cell auto" v-html="langConv('Bookmaker')"></div>
            <div class="cell shrink" style="margin-left:2px" v-html="langConv('Back/Lay')" v-if="freebie==false" ></div>
            <div class="cell small-1" style="margin-left:8px" v-html="langConv('Comm.')" v-if="freebie==false"></div>
            <div class="cell shrink" style="margin-left:6px" v-html="langConv('Currency')"></div>
            <div class="cell small-1" style="margin-left:2px"  v-html="langConv('Freebet')" v-if="freebie==false"></div>
            <div class="cell auto" v-html="langConv('Returns')"></div>
            <div class="cell auto" v-html="langConv('Odds')" v-if="freebie==false" ></div>
            <div class="cell auto" style="margin-right:2px" v-html="langConv('Stake')" v-if="freebie==false"></div>
          </div>
          <div v-for="(betline, id) in betlines" :key="id">
            <div class="grid-x" style="height:35px">
              <div class="cell shrink">
                <button
                  style="padding: 5px; line-height: 20px; font-weight: bold"
                  v-on:click="deleteLine(id)"
                >
                  x
                </button>
              </div>
              <div class="cell small-2">
                <input
        
                  style="
                    height: 30px;
                    border-radius: 5px;
                    border: solid lightgrey 1px;"
                  list="betnames"
                  v-model="betline.desc"
                  @change.self="calculateReturn(id, 'team')"
                  :disabled="gradedBet(betheaders)"
                />
                `
                <datalist id="betnames">
                  <option
                    v-for="(bo, i) in betoptions"
                    v-bind:value="bo"
                    :key="i"
                  >
                    {{ bo }}
                  </option>
                </datalist>
              </div>
              <div class="cell auto" style="margin-left:2px">
                <select  style="height:30px;" v-model="betline.bookmaker"  :disabled="gradedBet(betheaders)">
                  <option disabled selected value="" style="color: gray" 
                    v-html="langConv('Please select bookmaker')">
                  </option>
                  <option v-for="(bookmaker, i) in bookmakers" v-bind:value="bookmaker.v" :key="i">
                    {{ bookmaker.D }}
                  </option>
                </select>
              </div>

              <div class="cell shrink" style="margin-left:2px" v-if="freebie==false">
                <select style="height:30px;"
                  v-model="betline.backorlay"
                  v-on:change.self="calculateReturn(id, 'odds', 'back or lay')"
                  :disabled="gradedBet(betheaders)"
                  
                >
                  <option>B</option>
                  <option>L</option>
                </select>
              </div>

              <div class="cell small-1" style="margin-left:2px" v-if="freebie==false">
                <input
                  :disabled="gradedBet(betheaders)"
                  type="number"
                  step="0.1"
                  min="0"
                  style="height:30px"
                  v-model="betline.commissionrate"
                  @change.self="calculateReturn(id, 'commissionrate')"
                />
              </div>

              <div class="cell shrink" style="margin-left:2px" >
                <select
                  style="height:30px"
                  v-model="betline.exchangerate"
                  @change.self="calculateReturn(id, 'exchangerate')"
                  :disabled="gradedBet(betheaders)"
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
              <div class="cell small-1" style="padding: 3px" v-if="freebie==false">
                <input
                  type="checkbox"
                  style="height: 25px"
                  v-model="betline.freebet"
                  true-value="1"
                  false-value="0"
                  @change.self="calculateReturn(id, 'freebet')"
                  :disabled="gradedBet(betheaders)"
                />
              </div>
              <div class="cell auto" style="margin-left:1px">
                <input
                  type="number"
                  step="1"
                  style="height:30px"
                  min="0"
                  v-model="betline.returns"
                  @change.self="calculateReturn(id, 'returns')"
                  :disabled="gradedBet(betheaders)"
                />
              </div>
              <div class="cell auto" style="margin-left:1px" v-if="freebie==false">
                <input
                  type="number"
                  step="0.1"
                  min="1"
                  style="height:30px"
                  v-model="betline.odds"
                  v-on:change.self="
                    calculateReturn(id, 'odds', 'odds themselves')
                  "
                  :disabled="gradedBet(betheaders)"
                />
              </div>
              <div class="cell auto" style="margin-left:1px; margin-right:2px" v-if="freebie==false">
                <input
                  type="number"
                  step="1"
                  min="0"
                  style="height:30px"
                  v-model="betline.stake"
                  @change.self="calculateReturn(id, 'stake')"
                  :disabled="gradedBet(betheaders)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cell footer qsmallfontplus1 qpurple qwhitetext show-for-medium" style="padding: 5px; ; height: 100px">
      
      <div class="grid-x" >
        <div class="cell small-9">
          <div style="height: 85px; overflow: auto;">
            <DIV>{{langConv('Calculator Summary')}}</DIV>
            <template>
              <div v-for="(total, id) in returntotals" :key="id">
                <div class="grid-x" v-if="!(isNaN(total) || total == 0)">
                  <div class="cell small-6">{{ betoptions[id] }}</div>
                  <div class="cell small-6">{{ total }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="cell small-3">
            <div @mouseover="mouseQuadrant" class="qWhitetext" :class="{tooltip: hints}" >{{langConv('Currency Rates') }}
                <span  class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}">Click to select as a favourite</span>

            </div>
          <div class="grid-x">
            <div class="cell auto qwhitetext" style="padding:5px">
              {{langConv('Rate 1')}}<input
                type="number"
                step="0.1"
                min="0"
                max="50"
                v-model="forex[1]"
                @change="recalcAllLines()"
              />
            </div>
            <div class="cell auto qwhitetext" style="padding:5px">
              {{langConv('Rate 2')}}<input
                type="number"
                step="0.1"
                min="0"
                max="50"
                v-model="forex[2]"
                @change="recalcAllLines()"
              />
            </div>
            <div class="cell auto qwhitetext" style="padding:5px">
              {{langConv('Rate 3')}}<input
                type="number"
                step="0.1"
                min="0"
                max="50"
                v-model="forex[3]"
                @change="recalcAllLines()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="cell footer qpurple show-for-small-only qwhitetext" style="padding: 10px; ; height: 100px">
        
       <div class="grid-x" style="font-size: x-small">
          <div class="cell small-9">
            <div style="height: 80px; overflow: auto;">
              
                <template>
                <div v-for="(total, id) in returntotals" :key="id">
                  <div class="grid-x" v-if="!(isNaN(total) || total == 0)">
                    <div class="cell small-6">{{ betoptions[id] }}</div>
                    <div class="cell small-6">{{ total }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="cell small-3">
            
            <div class="grid-x">
               
              <div class="cell auto qwhitetext ">
                Rate 1<input
                  type="number"
                  step="1"
                  min="0"
                  v-model="forex[1]"
                  @change="recalcAllLines()"
                />
              </div>
              <div class="cell auto qwhitetext">
                Rate 2<input
                  type="number"
                  step="1"
                  min="0"
                  v-model="forex[2]"
                  @change="recalcAllLines()"
                />
              </div>
              <div class="cell auto qwhitetext">
                Rate 3<input
                  type="number"
                  step="1"
                  min="0"
                  v-model="forex[3]"
                  @change="recalcAllLines()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <modal
      name="savebetmodal"
      :adaptive="true"
      :minHeight="500"
      :maxWidth="300"
    >
      <div class="grid-x" style="padding: 10px">
        <h3 class="cell auto">Select Strategy</h3>
        <div  class="button small cell shrink" v-on:click="hidePopUp('savebetmodal')">
          x
        </div>
      </div>

      <div
        class="grid-x" style="text-align: center">
       
        <div class="cell small-10 small-offset-1">
          <dropdown
            ocname="retrievebetstrategies"
            @strategy_values="setStrategyValue"
            :ocnoitems="999"
            ocdefaultvalue="None"
            :ocdefaultselectedoptions="
              JSON.stringify({ v: 1, D: 'No Strategy' })
            "
            :occloseonselect="false"
            :ocmultiselect="false"
            :ocshowlistonstart="true"
            :ocheightoflist="250"
          ></dropdown>
        </div>
      </div>
      <div class="cell small-12" style="padding-top: 280px; text-align: center">
        <button class="button" v-on:click="saveBet()">Save</button>
      </div>
    </modal>
  </div>
</template>

<script>
import datepicker from "vue2-datepicker";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import CustomInput from "../components/customimport.vue";
import dropdown from "../navigation/dropdown.vue";
import axios from "axios";
import JsonExcel from "vue-json-excel";

export default {
  name: "hutchdutchcalc",
  data() {
    return {
      entrymode: '',
      view: "Bet Calculator",
      nobets: 1,
      payout: 0,
      forex: [1, 0, 0, 0],
      guid: "",
      entityids: "",
      bettypeids: "",
      betlines: [
        {
          desc: "",
          bookmaker: "",
          bookmaker_description: "",
          backorlay: "B",
          exchangerate: 0,
          commissionrate: 0,
          freebet: 0,
          returns: 0,
          odds: "1",
          stake: 0
        },
      ],
      betheaders: [],
      returntotals: [],
      selectedGUID: "",
      selectedRowID: 0,
      errors: [],
      fromdate: "",
      todate: "",
      searchval: "",
      betoptions: [],
      focused: false,
      value: "",
      results: [],
      selectedbets: [],
      noselectedbets: 0,
      bookmakers: [],
      lastbethistorysearch: "",
      morethanoneselectedbet: false,
      strategyvalue: 1,
      manualeventinput: "",
      startdate: "1/1/1970",
      freebie: false
    };
  },

  components: {
    datepicker,
    dropdown,
    Autocomplete,
    CustomInput,
    JsonExcel
  },
  computed: {
    noResults() {
      return this.value && this.results.length === 0;
    },
    hints () {
      return this.$store.state.showhints;
    }

},
  watch: {
    selectedbets: function () {
      if (this.selectedbets.length > 5) {
        this.morethanoneselectedbet = true;
      } else {
        this.morethanoneselectedbet = false;
      }
    },
  },

  created() {
    this.getBookmakers();
  },

  mounted() {
    for (let i = 1; i <= this.betoptions.length; i++) {
      this.returntotals.push(this.payout);
    }

    //get a guid for this bet
    this.guid = this.$uuidv4();
  },
  methods: {

    setforFreebies: function() {

        if (this.freebie == false) {
             this.freebie = true; 
             this.selectedbets = [];
             this.selectedbets.push({
                betno: 1,
                betname: 'no risk freebies',
                bettype: 'Freebie~',
                bettypeid: -1,
                entityid: -99,
                manualentered: true,
                startdate: Date(),
            })
            
            }
            else 
            { this.freebie = false;  }

    },

    concatBetHeaders: function() {

        let title = ''
    
        this.selectedbets.forEach(function (selectedbet) {
            
            title = title + selectedbet.betname + ' - ' + selectedbet.startdate 

        })

        //in case string is too long for a windows filename
        if (title.length > 250) {
            title = title.substring(0, 255);
        }

        return title;
        
    },
    
    excelifyBet: function() {

        let _this = this

        let excelversion = _this.betlines

        excelversion.forEach(function (betline) {

                     
           if (betline.bookmaker !== 0 && betline.bookmaker !== '') {

                betline.bookmaker_description = (_this.bookmakers.filter(rec => rec.v == betline.bookmaker))[0].D

           }

        })

        return excelversion

        //iterate replacing bookmakernumber with 

    },
     
    hidePopUp: function (val) {
      this.$modal.hide(val);
    },

    setStrategyValue: function (val) {
      this.strategyvalue = val.v;
    },

    addBetNameEntry: function (input) {

      //manually entered objects
      if (input === undefined) {

        this.selectedbets.push({
            betno: this.noselectedbets,
            betname: this.manualeventinput,
            bettype: "None~",
            bettypeid: -1,
            entityid: -1,
            manualentered: true,
            startdate: new Date()
        });

        this.entrymode = "manual"

      } else {



        //manually entered or retrieved from bet history
        if (input.betno != undefined) {
            this.noselectedbets = input.betno;
        } else {
            this.noselectedbets = Number(this.noselectedbets) + 1;
        }

        this.entrymode = "";

        this.selectedbets.push({
            betno: this.noselectedbets,
            betname: input.event,
            bettype: input.bettype,
            bettypeid: input.bettypeid,
            entityid: input.entityid,
            manualentered: input.manual != undefined ? input.manual : false,
            startdate: input.startdate,
        });

    }

      this.defineBetNames();
    },

    getBookmakers: function () {
      var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/SelectBookmaker";

      axios
        .get(URL, {
          params: {
            selectedbookmakers: JSON.stringify(this.$store.state.sb),
            noitems: 999,
            desc: "%%",
            minoddscount: 0,
          },
        })
        .then((response) => {
          this.bookmakers = JSON.parse(response.data);
        });
    },

    //create the entries that appear in the drop down boxes
    defineBetNames: function () {
      var self = this;
      var betnames;
      //clear down the bet options before recreating
      self.betoptions = [];

      var arrayLength = this.selectedbets.length;
      for (var iloop = 0; iloop < arrayLength; iloop++) {
        //has this been manually entered if so try and split by v if contains any or else return nothing
        if (self.selectedbets[iloop].manualentered === true) {
          if (self.selectedbets[iloop].betname.includes(" v ")) {
            this.bettype = "None";
            betnames = self
              .strip_html_tags(this.selectedbets[iloop].betname)
              .split(" v ");
            for (var i = 0; i < betnames.length; i++) {
              self.betoptions.push(
                this.selectedbets[iloop].betno + ". " + betnames[i]
              );
            }
            self.betoptions.push("draw / tie ?");
          } else {
            return [];
          }
        } else {
          //need to iterate through all autocomplete values on the form

          //Is the event tpo or OR
          if (self.selectedbets[iloop].betname.includes(" v ")) {
            betnames = self
              .strip_html_tags(this.selectedbets[iloop].betname)
              .split(" v ");

            //Remove Teams - Over Under Goals / Double Chance / Odd or Even
            if (
              bettype != "Over or Under Goals" ||
              bettype != "Odd or Even" ||
              bettype != "Over or Under Corners"
            ) {
              for (var i2 = 0; i2 < betnames.length; i2++) {
                self.betoptions.push(
                  this.selectedbets[iloop].betno + ". " + betnames[i2]
                );
              }
            }

            //Simple 2 outcomes - two team outcomes only do need to do anything else - Half Time / Score First/ MoneyLine
            if (
              bettype == "Score First " ||
              bettype == "Moneyline" ||
              bettype == "Tournament Bet"
            ) {
              continue;
            }

            // short name of draw (when multiple bets)
            var draw =
              "Draw (" +
              self.betoptions[self.betoptions.length - 2] +
              " - " +
              self.betoptions[self.betoptions.length - 1] +
              ")";

            //Get Bet Type and Extra Bet Info
            var bt = self.selectedbets[iloop].bettype;

            if (bt.indexOf("~") > -1) {
              var bettype = bt.substring(0, bt.indexOf("~"));
              var extrabetinfo = bt.substring(bt.indexOf("~") + 1, bt.length);
            }

            //Simple 3 outcomes - Match Odds / Half Time
            if (bettype == "Match Odds" || bettype == "Half Time") {
              self.betoptions.push(draw);
              continue;
            }

            //Enter specific values for specific bets
            if (
              bettype == "Over or Under Goals" ||
              bettype == "Over or Under Corners"
            ) {
              self.betoptions.push("Over~" + extrabetinfo);
              self.betoptions.push("Under~" + parseFloat(extrabetinfo) * -1);
              continue;
            }

            if (bettype == "Odd or Even") {
              self.betoptions.push("Odd");
              self.betoptions.push("Even");
              continue;
            }

            //Add Values to teams
            if (bettype == "Asian Handicaps" || bettype == "Handicaps") {
              self.betoptions[0] = self.betoptions[0] + "~" + extrabetinfo;
              self.betoptions[1] =
                self.betoptions[1] + "~" + parseFloat(extrabetinfo) * -1;
              continue;
            }

            //Double Chance - oddity
            if (bettype == "Double Chance") {
              self.betoptions.push(
                self.betoptions[0] + " or " + self.betoptions[1]
              );
              self.betoptions[0] = self.betoptions[0] + " or draw";
              self.betoptions[1] = self.betoptions[1] + " or draw";
              continue;
            }
          } else {
            //need to go and get teams associated with that competition outcome (need competitionid and outrightbettypeid)

            var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/TeamsforOutright";

            axios
              .get(URL, {
                params: {
                  CompetitionID: self.selectedbets[iloop].entityid,
                  OutRightBetTypeID: self.selectedbets[iloop].bettypeid,
                },
              })
              .then(
                (response) => {
                  if (response.data == "") {
                    this.selectedbets.pop();
                    this.genericConfirm(
                      `This competition currently has no competitors (with odds). Could be manually entry. So can not be edited`
                    );
                  } else {
                    let json = JSON.parse(response.data);

                    //convert json data into an array
                    json.forEach(function (bo) {
                      let betno = self.selectedbets[iloop - 1];
                      self.betoptions.push(betno.betno + ". " + bo.betname);
                    });
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        }
      }
    },

    deleteSelectedBet(selectedbetno) {
      //bet headers
      this.selectedbets = this.selectedbets.filter(function (values) {
        return values.betno != selectedbetno;
      });

      //also need to delete any bet lines desc which starts with the selected bet lines
      this.betlines = this.betlines.filter(function (values) {
        return values.desc.substring(0, 1).replace(/\D/g, "") != selectedbetno;
      });

      //reduce no bets

      this.nobets = this.betlines.length;

      this.defineBetNames();
    },

    //bet history sleect row function
    selectRow: function (GUID, id) {
      this.selectedGUID = GUID;
      this.selectedRowID = id;
    },

    //get full data for search results
    searchDropdownData: function (input) {
      if (input.length < 3) {
        return [];
      }

      var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/SearchResults";

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([]);
        }
        axios
          .get(URL, {
            params: {
              desc: input,
              noitems: 50,
              wildcard: true,
              sportid: -1,
            },
          })
          .then(
            (response) => {
              let data = JSON.parse(response.data)[0];

              if (Object.keys(data).length > 1) {
                data = data.BO;
              } else {
                return [];
              }

              return resolve(data);
            },
            (error) => {
              console.log(error);
            }
          );
      });
    },

    //get history bets for search results.

    //result formatted for appearance in drop down
    getResultFormattedValue: function (result) {
      if (result.bettype.includes("Match Odds")) {
        return (
          '<span style="color:lightgrey">' +
          new Date(result.startdate).toLocaleString().split(",")[0] +
          " " +
          result.league +
          "</span> - <mark>" +
          result.event +
          " (" +
          result.bettype +
          "</mark>)"
        );
      } else {
        return (
          '<span style="color:lightgrey">' +
          new Date(result.startdate).toLocaleString().split(",")[0] +
          " " +
          result.league +
          "</span> - " +
          result.event +
          " (" +
          result.bettype +
          ")"
        );
      }
    },

    //
    getResultValue: function (result) {
      var value = this.strip_html_tags(result.event);

      if (result.bettype != undefined) {
        value = value + " (" + result.bettype + ")";
      }

      return value;
    },

    noBets: function (val) {
      //add or remove item from betlines array
      if (val == 1) {
        this.betlines.push({
          desc: "",
          bookmaker: 0,
          backorlay: "B",
          commissionrate: 0,
          exchangerate: 0,
          freebet: 0,
          returns: 1,
          odds: "1",
          stake: 0,
        });
      }
      if (val == -1) {
        //cannot go below 1 betline

        if (this.nobets > 1) {
          this.betlines.splice(-1, 1);
        } else {
          val = 0;
        }
      }
      this.nobets += val;
    },

    //recalculate return for line
    calculateReturn: function (OCNo, colchanged, what) {
      let backorlay = this.betlines[OCNo].backorlay;
      let FreeBetValue;
      //Odds needs to go through the the ConvertOdds function to return everything in decimal
      let Odds = this.betlines[OCNo].odds;
      // If odds returned a valid type then proceed else handle gracefully
      if (Odds != 0) {
        let AmountToWin = parseFloat(this.betlines[OCNo].returns);
        let Stake = parseFloat(this.betlines[OCNo].stake);

        if (this.betlines[OCNo].freebet == "1") {
          FreeBetValue = Stake;
        } else {
          FreeBetValue = 0;
        }

        let cr = parseFloat(this.betlines[OCNo].commissionrate);
        //ensure odds value is numeric and either stake and/or winnings is numeric
        if (!isNaN(Odds)) {
          if (backorlay == "B") {
            if (!isNaN(AmountToWin) || !isNaN(Stake)) {
              // is it the winnings and the winnings has a numeric value?
              if (colchanged == "returns" && !isNaN(AmountToWin))
                Stake = AmountToWin / Odds;
              // is it the odds and the winnings has a numeric value?
              if (colchanged == "odds" && !isNaN(Stake))
                Stake = AmountToWin / Odds;
              // is it the odds and the winnings does not have a numeric value (therefore the stake must have)?
              if (colchanged == "odds" && !isNaN(AmountToWin))
                AmountToWin = Stake * Odds;
              // is it the stake?
              if (colchanged == "stake") AmountToWin = Stake * Odds;
              //
              // this happens when commission changes but also needs to occur if other 3 changed
              if (!isNaN(AmountToWin) && !isNaN(Stake))
                AmountToWin =
                  Stake * Odds -
                  (Stake * Odds - Stake) * (cr / 100) -
                  FreeBetValue;

              // to be done
              //frm.elements["commissiontaken[" + OutComeNo + "]"].value = '0';
              //if (!(isNaN(Stake))) Stake = AmountToWin / Odds;
              //if (!(isNaN(AmountToWin))) AmountToWin = Stake * Odds;
            }
          } else {
            if (!isNaN(AmountToWin) || !isNaN(Stake)) {
              //need to know what has been changed last

              // is it the winnings and the winnings has a numeric value?
              if (colchanged == "returns" && !isNaN(AmountToWin))
                Stake = AmountToWin * (Odds - 1);
              // is it the odds and the winnings has a numeric value?
              if (colchanged == "odds" && !isNaN(Stake))
                Stake = (AmountToWin + FreeBetValue) * (Odds - 1);
              if (isNaN(Stake)) Stake = 0;
              // is it the odds and the winnings does not have a numeric value (therefore the stake must have)?
              if (colchanged == "odds" && !isNaN(AmountToWin))
                AmountToWin = Stake / (Odds - 1);

              // is it the stake?
              if (colchanged == "stake") AmountToWin = Stake / (Odds - 1);
              // is it the currency
              // this happens when commission changes but also needs to occur if other 3 changed
              if (isNaN(AmountToWin) || AmountToWin === Infinity)
                AmountToWin = 0;

              if (!isNaN(AmountToWin) && !isNaN(Stake)) {
                if (Stake != 0 && Odds > 1) {
                  AmountToWin =
                    Stake / (Odds - 1) -
                    AmountToWin * (cr / 100) -
                    FreeBetValue;
                }
              }
            }
          }
        }

        if (Odds == "0") Stake = "0.00";

        //Set Values in Vue Calc Array]

        this.betlines[OCNo].returns = parseFloat(AmountToWin).toFixed(2);
        this.betlines[OCNo].odds = parseFloat(Odds).toFixed(2);
        this.betlines[OCNo].stake = parseFloat(Stake).toFixed(2);

        //recalculate all the returns at the top of the screen
        this.calculateAllReturns();
      } else {
        this.betlines[OCNo].odds = 1;
      }
    },

    //recalculate figures at bottom of calculator
    calculateAllReturns: function () {
      //empty previous returns calculation
      this.returntotals = [];

      let rt;
      let returns;
      let stake;
      let i;
      let thisrt;
      let forexrate = 1;
      let freebet;
      let ocno;

      this.payout = 0; //reset total payout

      //iterate through all the bets
      for (let betline of this.betlines) {
        //find outcome from the betoptions

        ocno = this.betoptions.indexOf(betline.desc);

        if (!isNaN(parseFloat(betline.returns))) {
          returns = parseFloat(betline.returns);
        } else {
          returns = 0;
        }

        //is the a stake a number
        if (!isNaN(parseFloat(betline.stake))) {
          stake = parseFloat(betline.stake);
        } else {
          stake = 0;
        }

        //is there already an entry for that outcome?
        if (!isNaN(parseFloat(this.returntotals[ocno]))) {
          rt = parseFloat(this.returntotals[ocno]);
        } else {
          rt = 0;
        }

        //is the forex other than 0 and if so find out what the exchange is
        if (this.exchangerate != 0) {
          forexrate = this.forex[betline.exchangerate];
        }

        //is this backorlay
        if (betline.backorlay == "B") {
          this.returntotals[ocno] = rt + parseFloat(returns) * forexrate; // array starts at 0 , outcomes start at 1
        } else {
          this.returntotals[ocno] = rt - parseFloat(stake) * forexrate; // array starts at 0 , outcomes start at 1

          //also need to iterate through other total return and odd on the lay gains
          for (i = 0; i < this.betoptions; i++) {
            if (i != ocno - 1) {
              if (!isNaN(parseFloat(this.returntotals[i]))) {
                thisrt = parseFloat(this.returntotals[i]);
              } else {
                thisrt = 0;
              }
              this.returntotals[i] = thisrt + parseFloat(returns) * forexrate;
            }
          }
        }

        this.payout += parseFloat(stake) * forexrate;
      }
    },

    //redundant - all odds entered in decimal
    convertOdds: function (Odds) {
      //Assume that the Odds type is invalid
      let OddsType = "Not Valid";

      //then find out what they are

      //American?

      if (Odds.charAt(0) == "+" || Odds.charAt(0) == "-") {
        let AmericanOdds = parseInt(Odds.substring(1, Odds.length));

        if (!isNaN(AmericanOdds)) {
          OddsType = "American";

          if (Odds.charAt(0) == "+") {
            Odds = 1 + 100 / AmericanOdds;
            return Odds;
          } else {
            Odds = 1 + AmericanOdds / 100;
            return Odds;
          }
        }
      }

      //Fractional?
      else {
        // is the a / in the odds entry
        let position = Odds.indexOf("/");

        if (!isNaN(parseInt(position))) {
          //is the part of the entry before the '/' an integer
          let nominator = parseInt(Odds.substring(0, position));

          if (!isNaN(nominator)) {
            //is the part of the entry after the '/' an integer
            let denominator = parseInt(
              Odds.substring(position + 1, Odds.length)
            );

            if (!isNaN(denominator)) {
              OddsType = "Fractional";
              Odds = nominator / denominator;
              return Odds;
            }
          }
        }
      }

      //Are the Odds Neither American nor Fractional
      if (OddsType == "Not Valid") {
        //attempt to convert the odds into a float data type
        let DecimalCheck = parseFloat(Odds);

        if (!isNaN(DecimalCheck)) {
          // is the float the same value as the original odds entry
          if (DecimalCheck == Odds) {
            OddsType = "Decimal";
            return Odds;
          }
        } else {
          Odds = "Not Valid";
          return Odds;
        }
      }
    },

    recalcAllLines: function () {
      //iteration through each bet
      for (let i = 0; i <= this.nobets - 1; i++) {
        this.calculateReturn(i, "odds", "recalc all lines");
      }
    },

    deleteLine: function (id) {
      if (this.betlines.length > 1) {
        this.betlines.splice(id, 1);

        this.nobets += -1;

        this.recalcAllLines();
      } else {
        this.genericConfirm(`There has to be at least one bet`);
      }
    },

    clearBet: function () {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            Object.assign(this.$data, this.$options.data.apply(this)); // smart way of resetthing the data() variables to initial values: TODO Clear the search box
            //reload bookmakers
            this.getBookmakers();
            this.guid = this.$uuidv4();
          }
        },
      });
    },

    selectStrategy: function () {

      //Are there any headers?
      if (this.selectedbets.length > 0 && this.betlines[0].desc != "") {
        //Select strategy
        this.$modal.show("savebetmodal");
      } else {
        alert("Need to have at least one Bet Title and one bet line");
      }
    },

    //save betheader and detail
    saveBet: function () {
      //check form entried valid
     
 
     let self = this;

      this.errors = [];

      //make title up of all the bets
      var title = "";
      var titlejoin = "";
      var entityids = "";
      var bettypeids = "";
      var idjoin = "";
      var startdate = "";

      this.selectedbets.forEach(function (selectedbet) {
        if (title != "") {
          titlejoin = " also ";
          idjoin = ",";
        }

        //add the titles together
        title =
          title +
          titlejoin +
          selectedbet.betno +
          ". " +
          selectedbet.betname +
          "(" +
          selectedbet.bettype +
          ")";


        startdate = startdate + idjoin +  self.$displayDate(selectedbet.startdate, self.$dateformattype.daymonth)



        //add the entity ids together
        entityids = entityids + idjoin + selectedbet.entityid;

        //add the bettype ids together
        bettypeids = bettypeids + idjoin + selectedbet.bettypeid;
      });

      if (this.errors.length > 0) {
        let eMessage = "Please correct the following errors\n";
        this.errors.forEach(function (value, index, array) {
          eMessage = eMessage + (index + 1) + ") " + value + "\n";
        });
        this.genericConfirm(eMessage);
        return;
      }



      //Save Bet Header
      axios
        .post(process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/SaveBetHeader", {
          GUID: this.guid,
          Title: title,
          NoBets: this.nobets,
          PayOut: this.payout,
          Forex: this.forex.toString(),
          EntityIDs: entityids,
          BetTypeIDs: bettypeids,
          UserID: this.$store.state.nameid,
          BetStrategy: this.strategyvalue,
          StartDate: startdate
  
        })
        .then((response) => {
       
          //then save the bet detail
          axios
            .post(
              process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/SaveBetDetail",
              {
                GUID: this.guid,
                jsonString: JSON.stringify(this.betlines),
              },
              (error) => {
                console.log(error);
              }
            )
            .then((response) => {
              this.genericConfirm(`Bet Saved Successfully`),
                (error) => {
                  console.log(error);
                };
            });
        });
    },

    backtoCalc: function () {
      //do they want to save current bet first as will be lost if it goes onto the
      this.betheaders = [];
      this.view = "Bet Calculator";
    },

    showBetHistory: function () {
      this.view = "bethistory";
      this.lastbethistorysearch = "%%";
      let d = new Date();
      this.todate = new Date(d.setDate(d.getDate() + 1));
      this.fromdate = new Date(d.setDate(d.getDate() - 180)); //last 7 days entries by default

      this.betHistory("");
    },

    betHistory: function (input) {
      this.lastbethistorysearch = input.event;

      //retrieve bet headers for that bet

      axios
        .get(process.env.VUE_APP_WEBAPI + "FeatureTables.svc/GetBetHeaders", {
          params: {
            noitems: 999,
            nameid: this.$store.state.nameid,
            country: this.$store.state.country,
            startdate: this.fromdate,
            enddate: this.todate,
            searchval: this.lastbethistorysearch,
            searchuser: "All",
            searchwinorlose: "All",
          },
        })
        .then((response) => {
          //check if anything in the response
          if (input.event === undefined) {
            this.lastbethistorysearch = "No Search Value Entered";
          }

          if (response.data == "") {
            throw new Error(
              "There is no bet history for selected dates and search value - " +
                this.lastbethistorysearch
            );
          }

          this.betheaders = JSON.parse(response.data);
          this.selectedGUID = this.betheaders[0].GUID;
        })
        .catch((error) =>
          //when throw "Error" is executed it runs the catch block code

          {
            //clear any exist search results
            this.betheaders = [];
            let specerror = "`" + error.message + "`";
            this.genericConfirm(specerror);
          }
        );
    },

    deleteBet: function () {
      //delete selected GUID entries from  betheader and betdetails
      axios
        .delete(process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/SimpleDelete", {
          data: {
            Table: "BetHeaders_NS",
            Where: "GUID = " + "'" + this.selectedGUID + "'",
          },
        })
        .then(
          (response) => {
            var input = { event: this.lastbethistorysearch };

            this.betHistory(input);

            this.genericConfirm(`Bet Deleted Successfully`);
          },

          (error) => {
            console.log("delete bet header - " + error);
          }
        );
    },

    getBetDetail: function () {
      this.genericYesNo(
        `This will clear any entries in the Calculator. Continue?`
      ).then((response) => {
        if (response == true) {
          //create the betheaders and the dropdown values before retrieving the lines
          //clear the selected bets list
          this.selectedbets = [];

          let individualbets =
            this.betheaders[this.selectedRowID].Title.split(" also ");

          //parse the entity ids into an array
          let entityids =
            this.betheaders[this.selectedRowID].EntityIDs.split(",");

          // parse the bettype ids
          let bettypeids =
            this.betheaders[this.selectedRowID].BetTypeIDs.split(",");

          // parse the startdates
        //   let startdates =
        //   this.betheaders[this.selectedRowID].StartDates.split(",");


          //populate the dropdown columns and the headers
          for (var iloop = 0; iloop < individualbets.length; iloop++) {
            var input = [];

            var ib = individualbets[iloop];

            input.betno = ib.substring(0, ib.indexOf(". "));
            input.event = ib.substring(ib.indexOf(". ") + 2, ib.indexOf("("));
            input.bettype = ib.substring(ib.indexOf("(") + 1, ib.indexOf(")"));
            input.bettypeid = bettypeids[iloop];
            input.entityid = entityids[iloop];
            // input.startdate = startdates[iloop];

            this.addBetNameEntry(input);
          }

          //retrieve bet details for that bet
          axios
            .get(process.env.VUE_APP_WEBAPI + "FeatureTables.svc/GetBetDetails", {
              params: {
                GUID: this.selectedGUID,
              },
            })
            .then((response) => {
              this.betlines = JSON.parse(response.data);

              //set bet header variables
              this.nobets = this.betheaders[this.selectedRowID].NoBets;
              this.payout = this.betheaders[this.selectedRowID].PayOut;
              this.forex = this.betheaders[this.selectedRowID].Forex.split(",");
              this.guid = this.betheaders[this.selectedRowID].GUID;
              this.bettypeids = this.betheaders[this.selectedRowID].bettypeids;
              this.entityids = this.betheaders[this.selectedRowID].entityids;

              this.view = "Bet Calculator";
              //recalculate totals
              this.calculateAllReturns();
            });
        }
      });
    },

    //auto complete component
    handleFocus() {
      this.focused = true;
    },

    //auto complete component
    handleBlur(obj) {
      this.focused = false;
    },

    valueColour(val) {
      let retVal = "Black";
      if (val < 0) retVal = "Red";
      if (val > 0) retVal = "Green";
      return retVal;
    },

    gradedBet(bh) {
      if (bh.length > 0 && bh[0].OverallGrading != 0) {
        return true;
      } else {
        return false;
      }
    },

    captureInput(val) {
    
      this.manualeventinput = val.target.value;
    },
  },
};
</script>


<style>
input[type="number"] {
  font-size: 11px;
}

input[type="text"] {
  font-size: x-small;
}

.highlight {
  background-color: gold;
}

.autocomplete-input-no-results.autocomplete-input-focused {
  border-bottom-color: transparent;
  border-radius: 8px 8px 0 0;
  font-size:11px;
}

.autocomplete-input-no-results:not(.autocomplete-input-focused)
  ~ .autocomplete-result-list {
  display: none;
}

input:disabled,
button[disabled] {
  color: black;
  background-color: whitesmoke;
}

select {
    font-size:11px
}

select:disabled,
button[disabled] {
  color: black;
  background-color: whitesmoke;
}
</style>



